import React, { useState, useRef, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  Tabs,
  Tab,
  Button,
  CircularProgress,
  Alert,
  Grid,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import UserAutocomplete from './UserAutocomplete';
import { API_BASE_URL } from '../constants';


function SystemMetrics() {
  const [tabValue, setTabValue] = useState(0);
  const [startTime, setStartTime] = useState(new Date(Date.now() - 2 * 60 * 60 * 1000));
  const [endTime, setEndTime] = useState(new Date());
  const [logs, setLogs] = useState([]);
  const [filteredLogs, setFilteredLogs] = useState([]);
  const [currentQueryInput, setCurrentQueryInput] = useState('');
  const [queries, setQueries] = useState([]);
  const [logLevels, setLogLevels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [users, setUsers] = useState([]);
  const [courses, setCourses] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedCourse, setSelectedCourse] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchError, setSearchError] = useState('');

  const [clusterInfo, setClusterInfo] = useState(null);
  const [k8sLoading, setK8sLoading] = useState(false);
  const [k8sError, setK8sError] = useState('');

  const logsEndRef = useRef(null);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setLogs([]);
    setFilteredLogs([]);
    setError('');
  };

  const handleFetchLogs = async () => {
    setLoading(true);
    setError('');
    setLogs([]);
    setFilteredLogs([]);

    try {
      let url = `${API_BASE_URL}/sget_logs`;
      const params = [];
      const formatTimeWithTwoDecimals = (time) => {
        const isoString = time.toISOString();
        return isoString.split('.')[0] + 'Z';
      };

      if (startTime) {
        params.push(`start_time=${encodeURIComponent(formatTimeWithTwoDecimals(startTime))}`);
      }
      if (endTime) {
        params.push(`end_time=${encodeURIComponent(formatTimeWithTwoDecimals(endTime))}`);
      }

      if (params.length > 0) {
        url += `?${params.join('&')}`;
      }

      const response = await fetch(url);
      const data = await response.json();

      if (data.success) {
        setLogs(data.logs || []);
        applyFilters(data.logs || []);
      } else {
        setError(data.error || 'Failed to fetch logs.');
      }
    } catch (err) {
      console.error('Error fetching logs:', err);
      setError('An error occurred while fetching logs.');
    } finally {
      setLoading(false);
    }
  };

  const applyFilters = (logsData) => {
    let allLogLines = [];
    logsData.forEach((logItem) => {
      const nodeName = logItem.node;
      logItem.logs.forEach((line) => {
        allLogLines.push({
          node: nodeName,
          line: `${nodeName} - ${line}`,
        });
      });
    });

    let filteredLogLines = allLogLines.filter((logEntry) => {
      const { line } = logEntry;
      let matchesQuery = true;
      let matchesLogLevel = true;

      if (queries.length > 0) {
        matchesQuery = queries.every((q) => {
          const isExclusion = q.startsWith('!');
          const cleanQuery = isExclusion ? q.substring(1) : q;
          const lineContainsQuery = line.toLowerCase().includes(cleanQuery.toLowerCase());

          return isExclusion ? !lineContainsQuery : lineContainsQuery;
        });
      }

      if (logLevels.length > 0) {
        matchesLogLevel = logLevels.some((level) => line.includes(level));
      }

      return matchesQuery && matchesLogLevel;
    });

    filteredLogLines = filteredLogLines.map((logEntry) => {
      const dateMatch = logEntry.line.match(
        /^(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2},\d{3}) -/
      );
      let date = null;
      if (dateMatch) {
        date = new Date(dateMatch[1].replace(',', '.'));
      }
      return {
        ...logEntry,
        date,
      };
    });

    filteredLogLines.sort((a, b) => {
      if (a.date && b.date) {
        return a.date - b.date;
      } else if (a.date) {
        return -1;
      } else if (b.date) {
        return 1;
      } else {
        return 0;
      }
    });

    setFilteredLogs(filteredLogLines);
  };

  const handleAddQuery = (e) => {
    if (e.key === 'Enter' && currentQueryInput.trim() !== '') {
      setQueries([...queries, currentQueryInput.trim()]);
      setCurrentQueryInput('');
    }
  };

  const handleDeleteQuery = (queryToDelete) => {
    setQueries(queries.filter((q) => q !== queryToDelete));
  };

  const fetchUsersAndCourses = async () => {
    try {
      // Fetch users
      const usersResponse = await fetch(`${API_BASE_URL}/sget/users`);
      const usersData = await usersResponse.json();
      if (usersData.success) {
        setUsers(usersData.users || []);
      } else {
        setSearchError(usersData.error || 'Failed to fetch users.');
      }

      // Fetch courses
      const coursesResponse = await fetch(`${API_BASE_URL}/sget/courses`);
      const coursesData = await coursesResponse.json();
      if (coursesData.success) {
        setCourses(coursesData.courses || []);
      } else {
        setSearchError(coursesData.error || 'Failed to fetch courses.');
      }
    } catch (error) {
      console.error('Error fetching users and courses:', error);
      setSearchError('An error occurred while fetching users and courses.');
    }
  };

  const handleSearch = async () => {
    if (!selectedUser || !selectedCourse) {
      setSearchError('Please select a user and a course.');
      return;
    }

    setSearchLoading(true);
    setSearchError('');
    setSearchResults([]);

    try {
      let url = `${API_BASE_URL}/sastra_retrieve/${selectedUser}`;
      const params = [];

      if (searchQuery) {
        params.push(`q=${encodeURIComponent(searchQuery)}`);
      }

      params.push(`subfolder=${encodeURIComponent(`${selectedCourse}/v0`)}`);

      if (params.length > 0) {
        url += `?${params.join('&')}`;
      }

      const response = await fetch(url);
      const data = await response.json();

      if (data.success) {
        setSearchResults(data.results || []);
      } else {
        setSearchError(data.error || 'Failed to fetch results.');
      }
    } catch (error) {
      console.error('Error fetching results:', error);
      setSearchError('An error occurred while fetching results.');
    } finally {
      setSearchLoading(false);
    }
  };

  useEffect(() => {
    applyFilters(logs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queries, logLevels]);

  useEffect(() => {
    if (logsEndRef.current) {
      logsEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [filteredLogs]);

  useEffect(() => {
    if (tabValue === 2) {
      fetchUsersAndCourses();
    }
    // Reset search errors and results when switching tabs
    setSearchError('');
    setSearchResults([]);
    setSearchQuery('');
    setSelectedUser('');
    setSelectedCourse('');
  }, [tabValue]);

  useEffect(() => {
    if (tabValue === 1) {
      // Fetch cluster info
      const fetchClusterInfo = async () => {
        setK8sLoading(true);
        setK8sError('');
        try {
          const response = await fetch(`${API_BASE_URL}/scluster-info`);
          const data = await response.json();
          if (data.success) {
            setClusterInfo(data);
          } else {
            setK8sError(data.error || 'Failed to fetch cluster info.');
          }
        } catch (error) {
          console.error('Error fetching cluster info:', error);
          setK8sError('An error occurred while fetching cluster info.');
        } finally {
          setK8sLoading(false);
        }
      };
      fetchClusterInfo();
    } else {
      // Reset when leaving the tab
      setClusterInfo(null);
      setK8sError('');
      setK8sLoading(false);
    }
  }, [tabValue]);

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        System Metrics
      </Typography>

      <Box sx={{ width: '100%', mt: 2 }}>
        <Tabs value={tabValue} onChange={handleTabChange} centered>
          <Tab label="Logs" />
          <Tab label="Kubernetes" />
          <Tab label="RAG Search" />
        </Tabs>

        {tabValue === 0 && (
          <Box sx={{ mt: 4 }}>
            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}

            <Grid container spacing={2} alignItems="center" justifyContent="center">
              <Grid item xs={12} sm={4}>
                <Typography>Start Time</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    label="Start Time"
                    value={startTime}
                    onChange={(newValue) => {
                      setStartTime(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Typography>End Time</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    label="End Time"
                    value={endTime}
                    onChange={(newValue) => {
                      setEndTime(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Typography>Search Queries</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Enter search query and press Enter"
                  value={currentQueryInput}
                  onChange={(e) => setCurrentQueryInput(e.target.value)}
                  onKeyDown={handleAddQuery}
                />
                <Box sx={{ mt: 1 }}>
                  {queries.map((query, index) => (
                    <Chip
                      key={index}
                      label={query}
                      onDelete={() => handleDeleteQuery(query)}
                      sx={{ mr: 1, mb: 1 }}
                    />
                  ))}
                </Box>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Typography>Log Levels</Typography>
                <FormGroup row>
                  {['DEBUG', 'INFO', 'WARNING', 'ERROR', 'CRITICAL'].map((level) => (
                    <FormControlLabel
                      key={level}
                      control={
                        <Checkbox
                          checked={logLevels.includes(level)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setLogLevels([...logLevels, level]);
                            } else {
                              setLogLevels(logLevels.filter((l) => l !== level));
                            }
                          }}
                        />
                      }
                      label={level}
                    />
                  ))}
                </FormGroup>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleFetchLogs}
                    disabled={loading}
                  >
                    Fetch Logs
                  </Button>
                </Box>
              </Grid>
            </Grid>

            {loading && (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                <CircularProgress />
              </Box>
            )}

            {!loading && filteredLogs.length > 0 && (
              <Box sx={{ mt: 4 }}>
                <Typography variant="h6">Logs:</Typography>
                <Box
                  sx={{
                    maxHeight: '70vh',
                    overflow: 'auto',
                    backgroundColor: '#f5f5f5',
                    p: 2,
                    mt: 2,
                    borderRadius: 2,
                  }}
                >
                  <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                    {filteredLogs.map((logEntry, idx) => (
                      <div key={idx}>{logEntry.line}</div>
                    ))}
                    <div ref={logsEndRef} />
                  </pre>
                </Box>
              </Box>
            )}

            {!loading && filteredLogs.length === 0 && logs.length > 0 && (
              <Box sx={{ mt: 4 }}>
                <Alert severity="info">No logs match the current filters.</Alert>
              </Box>
            )}
          </Box>
        )}

        {tabValue === 1 && (
          <Box sx={{ mt: 4 }}>
            {k8sError && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {k8sError}
              </Alert>
            )}

            {k8sLoading && (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                <CircularProgress />
              </Box>
            )}

            {!k8sLoading && clusterInfo && (
              <Box>
                <Typography variant="h6">Nodes:</Typography>
                {clusterInfo.nodes.map((node) => (
                  <Box key={node.name} sx={{ mt: 2 }}>
                    <Box
                      sx={{
                        border: '1px solid #ccc',
                        borderRadius: 2,
                        p: 2,
                        backgroundColor: node.status === 'Ready' ? '#e8f5e9' : '#ffebee',
                      }}
                    >
                      <Typography variant="subtitle1">{node.name}</Typography>
                      <Typography variant="body2">Status: {node.status}</Typography>
                    </Box>
                  </Box>
                ))}
                <Box sx={{ mt: 2 }}>
                  <Typography variant="subtitle2">Pods:</Typography>
                  <Grid container spacing={2}>
                    {clusterInfo.pods.map((pod) => (
                      <Grid item xs={12} sm={6} md={4} key={`${pod.namespace}-${pod.name}`}>
                        <Box
                          sx={{
                            border: '1px solid #ccc',
                            borderRadius: 2,
                            p: 2,
                            backgroundColor:
                              pod.status === 'Running' ? '#e8f5e9' :
                              pod.status === 'Pending' ? '#fff3e0' :
                              '#ffebee',
                          }}
                        >
                          <Typography variant="subtitle1">{pod.name}</Typography>
                          <Typography variant="body2">
                            Namespace: {pod.namespace}
                          </Typography>
                          <Typography variant="body2">Status: {pod.status}</Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
            )}

            {!k8sLoading && !clusterInfo && !k8sError && (
              <Typography variant="body1">No cluster information available.</Typography>
            )}
          </Box>
        )}

        {tabValue === 2 && (
          <Box sx={{ mt: 4 }}>
            {searchError && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {searchError}
              </Alert>
            )}

            <Grid container spacing={2} alignItems="center" justifyContent="center">
              <Grid item xs={12} sm={4}>
                <UserAutocomplete
                  users={users}
                  userId={selectedUser}
                  onUserChange={(newUserId) => setSelectedUser(newUserId)}
                  label="Select User"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel id="course-select-label">Course</InputLabel>
                  <Select
                    labelId="course-select-label"
                    value={selectedCourse}
                    label="Course"
                    onChange={(e) => setSelectedCourse(e.target.value)}
                  >
                    {courses.map((course) => (
                      <MenuItem key={course.subfolder} value={course.subfolder}>
                        {course.course_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Search Query"
                  placeholder="Enter search query"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSearch}
                    disabled={searchLoading}
                  >
                    Search
                  </Button>
                </Box>
              </Grid>
            </Grid>

            {searchLoading && (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                <CircularProgress />
              </Box>
            )}

            {!searchLoading && searchResults.length > 0 && (
              <Box sx={{ mt: 4 }}>
                <Typography variant="h6">Results:</Typography>
                <Box
                  sx={{
                    maxHeight: '70vh',
                    overflow: 'auto',
                    backgroundColor: '#f5f5f5',
                    p: 2,
                    mt: 2,
                    borderRadius: 2,
                  }}
                >
                  {searchResults.map((result, index) => (
                    <Chip key={index} label={result} sx={{ mr: 1, mb: 1 }} />
                  ))}
                </Box>
              </Box>
            )}

            {!searchLoading && !searchError && searchResults.length === 0 && (
              <Box sx={{ mt: 4 }}>
                <Alert severity="info">No results found.</Alert>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Container>
  );
}

export default SystemMetrics;
