import React, { useState } from 'react';
import {
  Container,
  Box,
  TextField,
  Button,
  Typography,
  CircularProgress,
} from '@mui/material';


function FetchPage() {
  const [url, setUrl] = useState('');
  const [filename, setFilename] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleFilenameGeneration = (inputUrl) => {
    try {
      const urlObj = new URL(inputUrl);
      let generatedFilename =
        urlObj.hostname +
        urlObj.pathname.replace(/[^a-z0-9]/gi, '_').toLowerCase();
      if (!generatedFilename.endsWith('.html')) {
        generatedFilename += '.html';
      }
      setFilename(generatedFilename);
    } catch (error) {
      console.error('Invalid URL');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');

    try {
      const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
      });

      if (response.ok) {
        const text = await response.text();
        const blob = new Blob([text], { type: 'text/html' });
        const downloadUrl = window.URL.createObjectURL(blob);

        // Trigger download
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = filename || 'download.html';
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(downloadUrl);

        setMessage('Success: File downloaded');
      } else {
        setMessage('Error: Failed to fetch HTML');
      }
    } catch (error) {
      console.error(error);
      setMessage('Error: An error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Fetch Fully Rendered HTML
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="URL"
            variant="outlined"
            fullWidth
            margin="normal"
            value={url}
            onChange={(e) => {
              setUrl(e.target.value);
              handleFilenameGeneration(e.target.value);
            }}
            required
          />
          <TextField
            label="Save to Filename"
            variant="outlined"
            fullWidth
            margin="normal"
            value={filename}
            onChange={(e) => setFilename(e.target.value)}
            required
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            sx={{ mt: 2 }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Fetch and Save'}
          </Button>
        </form>
        {message && (
          <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
            {message}
          </Typography>
        )}
      </Box>
    </Container>
  );
}

export default FetchPage;
