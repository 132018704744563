// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

// import * as Sentry from "@sentry/react";

// Sentry.init({
//   dsn: "https://fb911b77acbb2e94bf92dc9effc37f01@o4508014262222848.ingest.us.sentry.io/4508014382350336",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   // Tracing
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["localhost", /^https:\/\/lokos\.ai\/api/, /^https:\/\/lokos\/.ai/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
