import React, { useState, useRef, useEffect } from 'react';
import { createClient } from '@anam-ai/js-sdk';
import { AnamEvent } from '@anam-ai/js-sdk/dist/module/types';
import { API_BASE_URL, GOOGLE_CLIENT_ID, DOMAIN_NAME } from '../constants';
import { jwtDecode } from 'jwt-decode';

/* global google */ // To avoid ESLint warnings about 'google' being undefined

function AnamAIComponent() {
  const [sessionToken, setSessionToken] = useState(null);
  const [user, setUser] = useState(null);
  const [authorized, setAuthorized] = useState(false);
  const [error, setError] = useState('');
  const videoRef = useRef(null);
  const audioRef = useRef(null);
  const anamClientRef = useRef(null);

  // Google Sign-In handler
  const handleCredentialResponse = (response) => {
    const decoded = jwtDecode(response.credential);
    if (decoded.hd === DOMAIN_NAME) {
      setUser(decoded);
      setAuthorized(true);
      localStorage.setItem('user', JSON.stringify(decoded));
      localStorage.setItem('expiry', Date.now() + 3600000); // 1 hour in milliseconds
    } else {
      alert(`Access restricted to ${DOMAIN_NAME} email addresses.`);
    }
  };

  useEffect(() => {
    const loadGoogleSignIn = () => {
      if (typeof window.google !== 'undefined' && window.google.accounts && window.google.accounts.id) {
        window.google.accounts.id.initialize({
          client_id: GOOGLE_CLIENT_ID,
          callback: handleCredentialResponse,
        });
        window.google.accounts.id.renderButton(
          document.getElementById('googleSignInDiv'),
          { theme: 'outline', size: 'large', hosted_domain: DOMAIN_NAME }
        );
      } else {
        setTimeout(loadGoogleSignIn, 100);
      }
    };

    const storedUser = localStorage.getItem('user');
    const expiry = localStorage.getItem('expiry');
    if (storedUser && expiry && Date.now() < expiry) {
      setUser(JSON.parse(storedUser));
      setAuthorized(true);
    } else {
      localStorage.removeItem('user');
      localStorage.removeItem('expiry');
      loadGoogleSignIn();
    }
  }, []);

  // Fetch session token
  useEffect(() => {
    if (!authorized) return;

    const fetchSessionToken = async () => {
      setError('');
      try {
        const response = await fetch(`${API_BASE_URL}/get-anam-session-token`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });
        const data = await response.json();

        if (!data.sessionToken) {
          setError('Session token not received');
          return;
        }
        setSessionToken(data.sessionToken);
      } catch (err) {
        console.error('An error occurred:', err);
        setError(err.message);
      }
    };

    fetchSessionToken();
  }, [authorized]);

  // Initialize Anam client
  useEffect(() => {
    if (!sessionToken) return;

    const initializeAnamClient = async () => {
      try {
        if (!videoRef.current || !audioRef.current) {
          console.error('Video or audio element not available');
          return;
        }

        const anamClient = createClient(sessionToken, {
          personaId: '773a8ca8-efd8-4449-9305-8b8bc1591475',
          disableBrains: false,
          disableFillerPhrases: false,
        });
        anamClientRef.current = anamClient;

        anamClient.addListener(AnamEvent.CONNECTION_ESTABLISHED, () => console.log('Connection established'));
        anamClient.addListener(AnamEvent.VIDEO_PLAY_STARTED, () => console.log('Video started streaming'));
        anamClient.addListener(AnamEvent.CONNECTION_CLOSED, (reason) => console.log('Connection closed:', reason));
        anamClient.addListener(AnamEvent.ERROR, (error) => console.error('Anam client error:', error));

        await anamClient.streamToVideoAndAudioElements(videoRef.current.id, audioRef.current.id);
      } catch (err) {
        console.error('An error occurred during Anam client initialization:', err);
        setError(err.message);
      }
    };

    initializeAnamClient();

    return () => {
      if (anamClientRef.current) {
        anamClientRef.current.stopStreaming().catch((error) => console.error('Failed to stop streaming:', error));
        anamClientRef.current.removeAllListeners();
      }
    };
  }, [sessionToken]);

  if (!authorized) {
    return <div id="googleSignInDiv" style={{ display: 'flex', justifyContent: 'center', marginTop: '20%' }}></div>;
  }

  return (
    <div>
      {error && <div>Error: {error}</div>}
      <div>
        <video id="avatar-video" ref={videoRef} autoPlay playsInline width="640" height="480"></video>
        <audio id="avatar-audio" ref={audioRef} autoPlay></audio>
      </div>
    </div>
  );
}

export default AnamAIComponent;
