// src/components/TaskFlowChart.js

import React, { useState, useEffect } from 'react';
import ReactFlow, { ReactFlowProvider, MarkerType, Background } from 'reactflow';
import 'reactflow/dist/style.css';
import dagre from 'dagre';
import { Box } from '@mui/material';
import { formatInTimeZone } from 'date-fns-tz';

function TaskFlowChart({ tasks, onNodeClick }) {
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);

  useEffect(() => {
    if (tasks.length > 0) {
      const { nodes, edges } = generateNodesAndEdges(tasks);
      setNodes(nodes);
      setEdges(edges);
    } else {
      setNodes([]);
      setEdges([]);
    }
  }, [tasks]);

  useEffect(() => {
    if (nodes.length > 0 && reactFlowInstance) {
      const leftmostNode = nodes.reduce((prev, curr) =>
        curr.position.x < prev.position.x ? curr : prev
      );
      reactFlowInstance.setCenter(leftmostNode.position.x, leftmostNode.position.y, { zoom: 1 });
    }
  }, [nodes, reactFlowInstance]);

  const formatDateToEDT = (utcDateStr) => {
    const date = new Date(utcDateStr);
    const timeZone = 'America/New_York';
    return formatInTimeZone(date, timeZone, "MMMM d, yyyy h:mm a 'EDT'");
  };

  const generateNodesAndEdges = (tasks) => {
    const colorMap = {
      CANCELLED: 'red',
      UNSTARTED: 'gray',
      ONGOING: 'blue',
      COMPLETED: 'green',
    };

    const nodes = tasks.map((task) => {
      const color = colorMap[task.state] || 'black';

      const data = {
        label: (
          <div
            style={{
              padding: '10px',
              color: 'white',
              backgroundColor: color,
              borderRadius: '15px',
              border: '2px solid white',
              textAlign: 'center',
              height: '100%',
              boxSizing: 'border-box',
              fontSize: '26px', // Increase font size
              wordWrap: 'break-word', // Ensure text wraps
              whiteSpace: 'normal', // Allow text to break into multiple lines
              display: 'flex', // Use flexbox
              flexDirection: 'column', // Arrange children vertically
              justifyContent: 'center', // Vertically center content
              alignItems: 'center', // Horizontally center content
            }}
          >
            <div>{`Name: ${task.name}` || 'No Name'}</div>
            <div>{`ID: ${task.id.substring(0, 8)}` || 'No ID'}</div>
            <div>{(task.default_channel === 'RetellWrapper' ? 'Phone Call' : 'Secondary Channel') || 'No Channel'}</div>
            {task.begin_timestamp && (
              <div>{'➡️: ' + formatDateToEDT(task.begin_timestamp)}</div>
            )}
            {task.end_timestamp && (
              <div>{'⬅️: ' + formatDateToEDT(task.end_timestamp)}</div>
            )}
            {task.last_update && (
              <div>{'♻️: ' + formatDateToEDT(task.last_update)}</div>
            )}
          </div>
        ),
        task: task,
      };

      return {
        id: String(task.id),
        data: data,
        position: { x: 0, y: 0 },
        sourcePosition: 'bottom',
        targetPosition: 'top',
        style: {
          width: 500,
          height: 400,
          borderRadius: 20,
          border: '2px solid white',
          overflow: 'hidden',
        },
      };
    });

    const edges = tasks
      .filter((task) => task.parent_id)
      .map((task) => {
        return {
          id: `e${task.parent_id}-${task.id}`,
          source: String(task.parent_id),
          target: String(task.id),
          type: 'smoothstep',
          markerEnd: {
            type: MarkerType.ArrowClosed,
          },
          animated: true,
        };
      });

    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      nodes,
      edges
    );

    return { nodes: layoutedNodes, edges: layoutedEdges };
  };

  const getLayoutedElements = (nodes, edges, direction = 'LR') => {
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));

    const nodeWidth = 500;
    const nodeHeight = 400;

    dagreGraph.setGraph({ rankdir: direction });

    nodes.forEach((node) => {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    nodes.forEach((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      node.position = {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      };
    });

    return { nodes, edges };
  };

  const handleNodeClickInternal = (event, node) => {
    if (onNodeClick) {
      onNodeClick(event, node);
    }
  };

  return (
    <Box sx={{ height: 500, mt: 4 }}>
      <ReactFlowProvider>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          fitView
          onNodeClick={handleNodeClickInternal}
          onInit={setReactFlowInstance}
        >
          <Background color="#aaa" gap={16} />
        </ReactFlow>
      </ReactFlowProvider>
    </Box>
  );
}

export default TaskFlowChart;
