import React, { useState, useRef, useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Text, Html } from '@react-three/drei';

const linspace = (start, end, num) => {
  const step = (end - start) / (num - 1);
  return Array.from({ length: num }, (_, i) => start + step * i);
};

const ScatterPlot = ({ data }) => {
  const meshRef = useRef();
  const [clickedPoint, setClickedPoint] = useState(null); // State to track the clicked point

  // Optional cleanup effect for controlling WebGL resource disposal
  useEffect(() => {
    return () => {
      if (meshRef.current) {
        if (meshRef.current.geometry) meshRef.current.geometry.dispose();
        if (meshRef.current.material) meshRef.current.material.dispose();
      }
    };
  }, []);

  // Function to handle point click (toggle label on/off)
  const handleClick = (point, index) => {
    setClickedPoint((prevIndex) => (prevIndex === index ? null : index)); // Toggle point selection
  };

  const renderPoints = () =>
    data.map((point, index) => (
      <group key={index} position={[point.x, point.y, point.z]}>
        <mesh
          ref={meshRef}
          onClick={() => handleClick(point, index)} // Add onClick handler
        >
          <sphereGeometry args={[0.02, 32, 32]} />
          <meshStandardMaterial color={point.color || 'red'} />
        </mesh>

        {/* Conditionally render the label only when the point is clicked */}
        {clickedPoint === index && (
          <Text
            position={[0, 0.1, 0]} // Position the text slightly above the point
            fontSize={0.1}          // Adjust the font size for visibility
            color="black"           // Label color
            anchorX="center"        // Center the text horizontally
            anchorY="middle"        // Center the text vertically
          >
            {`Type of latency ${point.key} - (E2E = ${point.x.toFixed(2)}, %LLM = ${point.y.toFixed(2)}, Call mins = ${point.z.toFixed(2)})`}
          </Text>
        )}
      </group>
    ));

  // Render axis labels based on linspace
  const renderAxisLabels = () => {
    const numTicks = 21; // Number of ticks/labels per axis

    const xTicks = linspace(0, 10, numTicks);
    const yTicks = linspace(0, 1, numTicks);
    const zTicks = linspace(0, 5, numTicks);

    return (
      <>
        {/* X-axis labels */}
        {xTicks.map((tick, i) => (
          <Text key={`x-${i}`} position={[tick, 0, 0]} fontSize={0.1} color="black" anchorX="center" anchorY="middle">
            {tick.toFixed(2)}
          </Text>
        ))}

        {/* Y-axis labels */}
        {yTicks.map((tick, i) => (
          <Text key={`y-${i}`} position={[0, tick, 0]} fontSize={0.1} color="black" anchorX="center" anchorY="middle">
            {tick.toFixed(2)}
          </Text>
        ))}

        {/* Z-axis labels */}
        {zTicks.map((tick, i) => (
          <Text key={`z-${i}`} position={[0, 0, tick]} fontSize={0.1} color="black" anchorX="center" anchorY="middle">
            {tick.toFixed(2)}
          </Text>
        ))}
      </>
    );
  };

  return (
    <Canvas camera={{ position: [5, 5, 10], fov: 50 }} style={{ width: '100%', height: '500px' }}>
      <ambientLight />
      <pointLight position={[10, 10, 10]} />
      <axesHelper args={[10]} /> {/* Axis helper with a length of 10 to match your data scale */}
      {renderAxisLabels()} {/* Add axis labels */}
      {data.length ? renderPoints() : (
        <Html center>
          <div style={{ color: 'black', fontSize: '20px' }}>No data available</div>
        </Html>
      )}
      <OrbitControls enableZoom={true} />
    </Canvas>
  );
};

export default ScatterPlot;
