import React from 'react';
import { motion } from 'framer-motion';

function Contact() {
  return (
    <div
      style={{
        fontFamily: "'Arial', sans-serif",
        color: '#6B5755',
        backgroundColor: '#ffffff',
        overflowX: 'hidden',
      }}
    >
      {/* Navbar */}
      <nav
        style={{
          position: 'fixed',
          top: 0,
          width: '100%',
          backgroundColor: '#ffffff',
          borderBottom: '1px solid #f0f0f0',
          zIndex: 100,
          display: 'flex',
          justifyContent: 'center',
          padding: '10px 0',
        }}
      >
        <div style={{ display: 'flex', gap: '30px' }}>
          <a href="/" style={{ textDecoration: 'none', color: '#6B5755' }}>
            Home
          </a>
          <a href="/about" style={{ textDecoration: 'none', color: '#6B5755' }}>
            About
          </a>
          <a href="/contact" style={{ textDecoration: 'none', color: '#9C3336', fontWeight: 'bold' }}>
            Contact
          </a>
        </div>
      </nav>

      <div style={{ marginTop: '80px', padding: '100px 20px', textAlign: 'center' }}>
        <motion.h1
          style={{ fontSize: '3rem', color: '#9C3336', marginBottom: '20px' }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          Contact Us
        </motion.h1>
        <motion.p
          style={{ fontSize: '1.2rem', lineHeight: '1.8', marginBottom: '40px' }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 1 }}
        >
          We'd love to hear from you! Whether you have a question about our services, pricing, or
          anything else, our team is ready to answer all your questions.
        </motion.p>
        {/* Placeholder for contact details or a contact form */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1, duration: 1 }}
        >
          <p style={{ fontSize: '1rem' }}>
            We're based in Lower Manhattan
          </p>
          <p style={{ fontSize: '1rem', marginBottom: '10px' }}>
            <strong>Email:</strong> diego@lokos.ai
          </p>
        </motion.div>
      </div>
    </div>
  );
}

export default Contact;
