// Prompts.js
import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  TextField,
  MenuItem,
  Button,
  Grid,
  Box,
  Alert,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import MetricsPopup from './MetricsPopup';
import UserAutocomplete from './UserAutocomplete';
import { API_BASE_URL } from '../constants';


function Prompts() {
  const [lessonNumber, setLessonNumber] = useState('1');
  const [lessonNumbers, setLessonNumbers] = useState([...Array(28).keys()].map(i => (i + 1).toString())); // Lessons "1"-"28"
  const [intro, setIntro] = useState('');
  const [prompt, setPrompt] = useState('');
  const [text, setText] = useState(''); // Add state for text field
  const [userId, setUserId] = useState('');
  const [users, setUsers] = useState([]); // List of users
  const [course, setCourse] = useState(null); // Store full selected course object
  const [courses, setCourses] = useState([]); // List of courses
  const [loading, setLoading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [resetSuccessMessage, setResetSuccessMessage] = useState('');
  const [resetErrorMessage, setResetErrorMessage] = useState('');
  const [isUpdateSubmitted, setIsUpdateSubmitted] = useState(true); // Tracks if update has been submitted
  const [addTimeLimits, setAddTimeLimits] = useState(false); // State for checkbox
  const [addEpic, setAddEpic] = useState(true); // State for add_epic checkbox, checked by default

  const [metrics, setMetrics] = useState([]); // State for metrics
  const [includeMetrics, setIncludeMetrics] = useState({}); // State for included metrics
  const [requiredMetrics, setRequiredMetrics] = useState({}); // State for required metrics
  const [showMetricModal, setShowMetricModal] = useState(false); // State for metric modal visibility

  // Fetch users for dropdown
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/sget/users`);
        const data = await response.json();
        setUsers(data.users || []);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, [API_BASE_URL]);

  // Fetch courses for dropdown
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/sget/courses`);
        const data = await response.json();
        setCourses(data.courses || []);
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    fetchCourses();
  }, [API_BASE_URL]);

  // Fetch metrics for reset modal
  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/sget/metrics`);
        const data = await response.json();
        if (data.success) {
          setMetrics(data.metrics || []);
          // Initialize includeMetrics state
          const initialIncludeMetrics = {};
          data.metrics.forEach(metric => {
            initialIncludeMetrics[metric.id] = true; // By default all included
          });
          setIncludeMetrics(initialIncludeMetrics);

          // Initialize requiredMetrics state
          const initialRequiredMetrics = {};
          data.metrics.forEach(metric => {
            initialRequiredMetrics[metric.id] = false; // By default none required
          });
          setRequiredMetrics(initialRequiredMetrics);
        } else {
          console.error('Failed to fetch metrics:', data.error);
        }
      } catch (error) {
        console.error('Error fetching metrics:', error);
      }
    };

    fetchMetrics();
  }, [API_BASE_URL]);

  // Fetch the current intro, prompt, and text for the selected lesson and course
  useEffect(() => {
    const fetchLessonData = async () => {
      if (!course) return;
      setLoading(true);
      setErrorMessage(''); // Reset error message before the fetch
      try {
        const response = await fetch(
          `${API_BASE_URL}/sget_instructions?lesson_number=${lessonNumber}&program=${course.subfolder}/v0`
        );
        const data = await response.json();

        if (data.success) {
          // If the API indicates success, populate the intro, prompt, and text fields
          setIntro(data.intro || '');
          setPrompt(data.prompt || '');
          setText(data.text || ''); // Handle optional text field
        } else {
          // If success is false, set the error message from the response
          setErrorMessage(data.error || 'Failed to fetch lesson data.');
        }
      } catch (error) {
        console.error('Error fetching lesson data:', error);
        setErrorMessage('An error occurred while fetching lesson data.');
      } finally {
        setLoading(false);
      }
    };

    fetchLessonData();
  }, [lessonNumber, course]);

  const handleCourseChange = (event) => {
    const selectedCourse = courses.find(c => c.id === event.target.value);
    setCourse(selectedCourse);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Reset messages
    setSuccessMessage('');
    setErrorMessage('');
    setResetSuccessMessage('');
    setResetErrorMessage('');

    if (!course) {
      setErrorMessage('Please select a course.');
      return;
    }

    // Prepare form data
    const formData = new URLSearchParams();
    formData.append('prompt', prompt);
    formData.append('intro', intro);
    formData.append('text', text); // Add text to form submission
    formData.append('lesson_number', lessonNumber);
    formData.append('course_id', course.id);  // Pass course_id instead of user_id or program

    try {
      const response = await fetch(`${API_BASE_URL}/sedit_prompt/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formData.toString(),
      });

      const result = await response.json();

      if (result.success) {
        setSuccessMessage('Lesson updated successfully!');
        setIsUpdateSubmitted(true);  // Mark that update was successfully submitted
      } else {
        setErrorMessage(`Failed to update the lesson: ${result.error}`);
      }
    } catch (error) {
      console.error('Error updating lesson:', error);
      setErrorMessage('An error occurred while updating the lesson.');
    }
  };

  const handleReset = () => {
    setShowMetricModal(true);
    // Reset messages
    setResetSuccessMessage('');
    setResetErrorMessage('');
    setSuccessMessage('');
    setErrorMessage('');
  };

  const handleMetricModalSubmit = async () => {
    setShowMetricModal(false);
    setResetLoading(true);
    setResetSuccessMessage('');
    setResetErrorMessage('');

    if (!course) {
      setResetErrorMessage('Please select a course.');
      setResetLoading(false);
      return;
    }

    try {
      const selectedMetricIds = metrics
        .filter(metric => includeMetrics[metric.id])
        .map(metric => metric.id);

      const requiredMetricIds = metrics
        .filter(metric => requiredMetrics[metric.id])
        .map(metric => metric.id);

      const params = new URLSearchParams();
      params.append('lesson_number', lessonNumber);
      params.append('add_time_limits', addTimeLimits);
      params.append('add_epic', addEpic);
      params.append('course_id', course.id);

      if (selectedMetricIds.length > 0) {
        params.append('metric_ids', selectedMetricIds.join(','));
      }

      if (requiredMetricIds.length > 0) {
        params.append('required_metric_ids', requiredMetricIds.join(','));
      }

      const url = `${API_BASE_URL}/sreset_user/${userId}?${params.toString()}`;
      const response = await fetch(url, {
        method: 'GET',
      });

      const result = await response.json();
      setResetLoading(false);

      if (result.success) {
        setResetSuccessMessage(`Successfully reset ${getUserName(userId)} from lesson ${lessonNumber}`);
        setIsUpdateSubmitted(false);
      } else {
        setResetErrorMessage(`Failed to reset the user: ${result.error}`);
      }
    } catch (error) {
      console.error('Error resetting user:', error);
      setResetErrorMessage('An unknown error occurred while resetting the user.');
      setResetLoading(false);
    }
  };

  const getUserName = (id) => {
    const user = users.find(u => u.id === id);
    return user ? `${user.first_name} ${user.last_name}` : '';
  };

  const isResetDisabled = !userId || resetLoading || !isUpdateSubmitted;

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Update Lesson
      </Typography>

      {/* Messages */}
      {successMessage && <Alert severity="success" sx={{ mb: 2 }}>{successMessage}</Alert>}
      {errorMessage && <Alert severity="error" sx={{ mb: 2 }}>{errorMessage}</Alert>}
      {resetSuccessMessage && <Alert severity="success" sx={{ mb: 2 }}>{resetSuccessMessage}</Alert>}
      {resetErrorMessage && <Alert severity="error" sx={{ mb: 2 }}>{resetErrorMessage}</Alert>}

      <Box component="form" onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2}>
          {/* Lesson Number */}
          <Grid item xs={12} sm={4}>
            <Autocomplete
              freeSolo
              options={lessonNumbers}
              value={lessonNumber}
              onChange={(event, newValue) => {
                setLessonNumber(newValue);
              }}
              onInputChange={(event, newInputValue) => {
                setLessonNumber(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Lesson Number"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>

          {/* Course Dropdown */}
          <Grid item xs={12} sm={4}>
            <TextField
              select
              label="Course"
              value={course ? course.id : ''}
              onChange={handleCourseChange}
              fullWidth
            >
              {courses.map((course) => (
                <MenuItem key={course.id} value={course.id}>
                  {course.course_name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* User ID Dropdown */}
          <Grid item xs={12} sm={4}>
            <UserAutocomplete
              users={users}
              userId={userId}
              onUserChange={setUserId}
              label="Select User (For reset only)"
              fullWidth
            />
          </Grid>

          {/* Checkbox for Add Time Limits */}
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={addTimeLimits}
                  onChange={(e) => setAddTimeLimits(e.target.checked)}
                  color="primary"
                />
              }
              label="Add daily time constraints (For reset only)"
            />
          </Grid>

          {/* Checkbox for Add EPIC */}
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={addEpic}
                  onChange={(e) => setAddEpic(e.target.checked)}
                  color="primary"
                />
              }
              label="Add EPIC (For reset only)"
            />
          </Grid>

          {/* Intro */}
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              Valid optional variables include {"{name}"}, {"{alias}"}, {"{therapist_name}"}, {"{entity}"}, and {"{profile_info}"}.
            </Typography>
            <TextField
              label="Intro"
              value={intro}
              onChange={(e) => setIntro(e.target.value)}
              required
              fullWidth
              multiline
              minRows={4}
            />
          </Grid>

          {/* Prompt */}
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              No variables allowed for prompt! Don't use curly braces!
            </Typography>
            <TextField
              label="Prompt"
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              required
              fullWidth
              multiline
              minRows={6}
            />
          </Grid>

          {/* Text Field */}
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              Valid optional variables include {"{name}"}, {"{alias}"}, {"{next_appointment}"}, {"{therapist_name}"}, {"{entity}"}, and {"{profile_info}"}.
            </Typography>
            <TextField
              label="Text Message (After Call)"
              value={text}
              onChange={(e) => setText(e.target.value)}
              fullWidth
              multiline
              minRows={4}
            />
          </Grid>

          {/* Update Lesson Button */}
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              size="large"
            >
              Update Lesson
            </Button>
          </Grid>

          {/* Reset Button */}
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="secondary"
              fullWidth
              size="large"
              disabled={isResetDisabled}
              onClick={handleReset}
            >
              {resetLoading
                ? 'Resetting...'
                : `Reset ${getUserName(userId)} from lesson ${lessonNumber}`}
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* Loading Indicator */}
      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      )}

      {/* Metric Selection Modal */}
      <MetricsPopup
        open={showMetricModal}
        onClose={() => setShowMetricModal(false)}
        metrics={metrics}
        includeMetrics={includeMetrics}
        setIncludeMetrics={setIncludeMetrics}
        requiredMetrics={requiredMetrics}
        setRequiredMetrics={setRequiredMetrics}
        onSubmit={handleMetricModalSubmit}
      />
    </Container>
  );
}

export default Prompts;
