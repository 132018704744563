import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, TextField } from '@mui/material';

const UserAutocomplete = ({ users, userId, onUserChange, label, fullWidth }) => {
  // Sort users alphabetically by full name
  const sortedUsers = React.useMemo(() => {
    return [...users].sort((a, b) => {
      const nameA = `${a.first_name} ${a.last_name}`.toUpperCase();
      const nameB = `${b.first_name} ${b.last_name}`.toUpperCase();
      return nameA.localeCompare(nameB);
    });
  }, [users]);

  // Find the selected user object based on userId
  const selectedUser = sortedUsers.find((user) => user.id === userId) || null;

  return (
    <Autocomplete
      options={sortedUsers}
      getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
      value={selectedUser}
      onChange={(event, newValue) => {
        onUserChange(newValue ? newValue.id : null);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField {...params} label={label} fullWidth={fullWidth} />
      )}
    />
  );
};

UserAutocomplete.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
    })
  ).isRequired,
  userId: PropTypes.any,
  onUserChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
};

UserAutocomplete.defaultProps = {
  userId: null,
  label: 'Select User',
  fullWidth: true,
};

export default UserAutocomplete;
