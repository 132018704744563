// MetricsPopup.js
import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
} from '@mui/material';


function MetricsPopup(props) {
  const {
    open,
    onClose,
    metrics,
    includeMetrics,
    setIncludeMetrics,
    requiredMetrics,
    setRequiredMetrics,
    onSubmit,
  } = props;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Select Metrics</DialogTitle>
      <DialogContent>
        <Typography variant="body2" gutterBottom>
          Please select the metrics to include and/or require:
        </Typography>
        {metrics.map((metric) => (
          <Box key={metric.id} sx={{ mb: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {metric.name}
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              <strong>Description:</strong> {metric.description}
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              <strong>Aggregated as:</strong> {metric.aggregation_rep}
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              <strong>Numerical conversion:</strong> {metric.numeric_rep}
            </Typography>
            <FormGroup row sx={{ mt: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={includeMetrics[metric.id] || false}
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      setIncludeMetrics({
                        ...includeMetrics,
                        [metric.id]: isChecked,
                      });
                      if (!isChecked) {
                        setRequiredMetrics({
                          ...requiredMetrics,
                          [metric.id]: false,
                        });
                      }
                    }}
                  />
                }
                label="Include"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={requiredMetrics[metric.id] || false}
                    onChange={(e) => {
                      setRequiredMetrics({
                        ...requiredMetrics,
                        [metric.id]: e.target.checked,
                      });
                    }}
                    disabled={!includeMetrics[metric.id]} // Cannot be required if not included
                  />
                }
                label="Required"
              />
            </FormGroup>
          </Box>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onSubmit} variant="contained" color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MetricsPopup;
