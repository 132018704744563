import React from 'react';
import { motion } from 'framer-motion';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';

function Home() {
  const particlesInit = async (engine) => {
    // Load the full tsparticles package, which includes all shapes and interactions
    await loadFull(engine);
  };

  return (
    <div
      style={{
        position: 'relative', // Added position relative
        fontFamily: "'Arial', sans-serif",
        color: '#6B5755',
        backgroundColor: '#ffffff',
        overflow: 'visible',
      }}
    >
      {/* Navbar */}
      <nav
        style={{
          position: 'fixed',
          top: 0,
          width: '100%',
          backgroundColor: '#ffffff',
          borderBottom: '1px solid #f0f0f0',
          zIndex: 100,
          display: 'flex',
          justifyContent: 'center',
          padding: '10px 0',
        }}
      >
        <div style={{ display: 'flex', gap: '30px' }}>
          <a href="/" style={{ textDecoration: 'none', color: '#9C3336', fontWeight: 'bold' }}>
            Home
          </a>
          <a href="/about" style={{ textDecoration: 'none', color: '#6B5755' }}>
            About
          </a>
          <a href="/contact" style={{ textDecoration: 'none', color: '#6B5755' }}>
            Contact
          </a>
        </div>
      </nav>

      {/* Particles Background */}
      <Particles
        id="tsparticles"
        init={particlesInit}
        options={{
          background: {
            color: { value: '#ffffff' },
          },
          fpsLimit: 60,
          interactivity: {
            events: {
              onHover: { enable: true, mode: 'grab' },
              resize: true,
            },
            modes: {
              grab: {
                distance: 200,
                links: { opacity: 0.5 },
              },
            },
          },
          particles: {
            color: { value: '#9C3336' },
            links: {
              color: '#6B5755',
              distance: 150,
              enable: true,
              opacity: 0.4,
              width: 1,
            },
            move: {
              enable: true,
              speed: 1,
              outModes: { default: 'bounce' },
            },
            number: {
              density: { enable: true, area: 800 },
              value: 80,
            },
            opacity: { value: 0.5 },
            shape: {
              type: 'circle',
            },
            size: {
              value: { min: 1, max: 3 },
            },
          },
          detectRetina: true,
        }}
        style={{
          position: 'absolute', // Changed from 'fixed' to 'absolute'
          zIndex: -1,
          width: '100%',
          height: '100%', // This should now cover the parent div's height
        }}
      />

      {/* Hero Section */}
      <section
        style={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          padding: '0 20px',
          marginTop: '60px', // Adjust for navbar height
          position: 'relative',
        }}
      >
        <div>
          <img
            src="/logo_no_bg.svg"
            alt="Lokos AI Logo"
            style={{ width: '50%', marginTop: '200px', marginBottom: '20px' }}
          />
          <motion.p
            style={{ fontSize: '1.5rem', marginTop: '10px' }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 1 }}
          >
            Redefine Success with AI Built for You
          </motion.p>
        </div>
        <div style={{ marginTop: 'auto', marginBottom: '20px', textAlign: 'center' }}>
          <span style={{ fontSize: '1rem', color: '#6B5755' }}>Scroll Down</span>
          <br />
          <motion.span
            animate={{ y: [0, 10, 0] }}
            transition={{ repeat: Infinity, duration: 2 }}
            style={{ display: 'inline-block', marginTop: '10px', fontSize: '2rem' }}
          >
            &#x25BC;
          </motion.span>
        </div>
      </section>

      {/* About Section */}
      <section
        style={{
          minHeight: '100vh',
          marginTop: '200px',
          width: '70%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', // Centers content horizontally within the flex container
          justifyContent: 'center', // Centers content vertically within the flex container
          textAlign: 'center',
          padding: '0 20px',
          margin: '0 auto', // Centers the div itself horizontally in the viewport
          position: 'relative',
        }}
      >

          <h2 style={{ color: '#9C3336', marginBottom: '20px', fontSize: '2.5rem' }}>
            Embracing the Future of AI
          </h2>
          <p style={{ fontSize: '1.2rem', lineHeight: '1.8', marginBottom: '20px' }}>
            In today's rapidly evolving digital landscape, Artificial Intelligence is not just a
            competitive advantage—it's a necessity. At Lokos AI, we specialize in bringing
            cutting-edge AI expertise directly into your business. Our powerful yet easy-to-manage
            custom solutions ensure seamless integration of new technology, helping you automate
            tasks and gain actionable insights while keeping operations simple.
          </p>
          <p style={{ fontSize: '1.2rem', lineHeight: '1.8', marginBottom: '20px' }}>
            As data becomes the new currency, harnessing AI's potential is crucial for staying
            ahead. Whether it's developing sophisticated language models, creating intelligent AI
            agents, or crafting financial forecasting tools, we're here to guide you through the AI
            revolution.
          </p>
          <p style={{ fontSize: '1.2rem', lineHeight: '1.8', marginBottom: '20px' }}>
            Our team brings unmatched expertise, honed from building cutting-edge solutions in both innovative startups and industry-leading enterprises. This diverse experience allows us to deliver AI-powered tools that not only drive efficiency but also unlock new opportunities for growth. With a proven track record of solving complex challenges, we are your trusted partner in navigating the future of technology with confidence and precision.
          </p>
      </section>

      {/* Services Section */}
      <section
        style={{
          padding: '100px 20px',
          backgroundColor: '#f9f9f9',
          position: 'relative',
        }}
      >
        <h2
          style={{
            textAlign: 'center',
            color: '#9C3336',
            marginBottom: '50px',
            fontSize: '2.5rem',
          }}
        >
          Our Services
        </h2>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            maxWidth: '1200px',
            margin: '0 auto',
          }}
        >
          {[
            {
              title: 'AI Strategy Development',
              description:
                'We identify opportunities where AI can add value and create a clear roadmap that aligns with your goals, leveraging both existing and potential data to maximize AI’s impact on your business.',
            },
            {
              title: 'AI Solutions',
              description:
                'We build custom AI applications across domains like text, vision, speech, and predictive analytics, designed to streamline operations and drive innovation.',
            },
            {
              title: 'AI Agents Development',
              description:
                'Our AI agents automate tasks and decision-making, enhancing efficiency and scaling operations while maintaining a natural, human-centric approach to automation.',
            },
            {
              title: 'Data Analysis and Visualization',
              description:
                'We unlock the full potential of your data with comprehensive analysis and intuitive visualizations that reveal growth opportunities and inform strategy.',
            },
            {
              title: 'AI Implementation and Integration',
              description:
                'We ensure smooth integration of AI solutions into your infrastructure, minimizing disruption and maximizing benefits.',
            },
            {
              title: 'Training and Workshops',
              description:
                'We provide hands-on training, empowering your team to confidently manage and leverage AI technologies for continued success.',
            },
          ].map((service, index) => (
            <motion.div
              key={index}
              style={{
                flex: '1 1 300px',
                margin: '20px',
                padding: '30px',
                borderRadius: '15px',
                backgroundColor: '#ffffff',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                textAlign: 'center',
                position: 'relative',
                overflow: 'hidden',
              }}
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: '-50px',
                  right: '-50px',
                  width: '100px',
                  height: '100px',
                  backgroundColor: '#9C3336',
                  borderRadius: '50%',
                  zIndex: '-1',
                }}
              ></div>
              <h3 style={{ color: '#9C3336', marginBottom: '15px', fontSize: '1.5rem' }}>
                {service.title}
              </h3>
              <p style={{ fontSize: '1rem', lineHeight: '1.6' }}>{service.description}</p>
            </motion.div>
          ))}
        </div>
      </section>

      {/* Footer */}
      <footer
        style={{
          backgroundColor: '#6B5755',
          color: '#ffffff',
          padding: '40px 20px',
          textAlign: 'center',
          position: 'relative',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: '-50px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '100px',
            height: '100px',
            backgroundColor: '#9C3336',
            borderRadius: '50%',
            zIndex: '-1',
          }}
        ></div>
        <p style={{ marginBottom: '10px', fontSize: '1.2rem' }}>
          &copy; {new Date().getFullYear()} Lokos AI
        </p>
        <p style={{ fontSize: '1rem' }}>All rights reserved.</p>
      </footer>
    </div>
  );
}

export default Home;
