// src/components/AdminDashboard.js

import React, { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box,
  Toolbar,
  CssBaseline,
  IconButton,
  Divider,
  Collapse,
  Container,
} from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import InboxIcon from '@mui/icons-material/Inbox';
import TaskIcon from '@mui/icons-material/Task';
import SchoolIcon from '@mui/icons-material/School';
import GroupIcon from '@mui/icons-material/Group';
import InsightsIcon from '@mui/icons-material/Insights';
import BarChartIcon from '@mui/icons-material/BarChart';
import ScheduleIcon from '@mui/icons-material/Schedule';
import MessageIcon from '@mui/icons-material/Message';

import Prompts from './Prompts';
import Tasks from './Tasks';
import Courses from './Courses';
import Users from './Users';
import SystemMetrics from './SystemMetrics';
import AppMetrics from './AppMetrics';
import Schedules from './Schedules';
import Messages from './Messages';
import { DOMAIN_NAME, GOOGLE_CLIENT_ID } from '../constants';


/* global google */ // To avoid ESLint warnings about 'google' being undefined

const drawerWidth = 240;

function AdminDashboard() {
  const [user, setUser] = useState(null);
  const [authorized, setAuthorized] = useState(false);
  const [open, setOpen] = useState(true); // State to toggle sidebar
  const [activeComponent, setActiveComponent] = useState('Prompts'); // Keep track of which component to render

  // Function to handle the Google Sign-In response
  const handleCredentialResponse = (response) => {
    const decoded = jwtDecode(response.credential);
    if (decoded.hd === DOMAIN_NAME) {
      setUser(decoded);
      setAuthorized(true);

      // Store the decoded token in localStorage
      localStorage.setItem('user', JSON.stringify(decoded));
      localStorage.setItem('expiry', Date.now() + 3600000); // 1 hour in milliseconds
    } else {
      alert(`Access restricted to ${DOMAIN_NAME} email addresses.`);
    }
  };

  useEffect(() => {
    const loadGoogleSignIn = () => {
      if (
        typeof window.google !== 'undefined' &&
        window.google.accounts &&
        window.google.accounts.id
      ) {
        window.google.accounts.id.initialize({
          client_id:
            GOOGLE_CLIENT_ID,
          callback: handleCredentialResponse,
        });
        window.google.accounts.id.renderButton(
          document.getElementById('googleSignInDiv'),
          {
            theme: 'outline',
            size: 'large',
            hosted_domain: DOMAIN_NAME,
          }
        );
      } else {
        setTimeout(loadGoogleSignIn, 100);
      }
    };

    // Check if user data is already in localStorage and valid
    const storedUser = localStorage.getItem('user');
    const expiry = localStorage.getItem('expiry');
    if (storedUser && expiry && Date.now() < expiry) {
      const decodedUser = JSON.parse(storedUser);
      setUser(decodedUser);
      setAuthorized(true);
    } else {
      // Clear any expired data
      localStorage.removeItem('user');
      localStorage.removeItem('expiry');

      // Start the process of loading Google Sign-In
      loadGoogleSignIn();
    }

    // Check if the active tab is stored in localStorage and still valid
    const storedTab = localStorage.getItem('activeTab');
    const tabExpiry = localStorage.getItem('tabExpiry');
    if (storedTab && tabExpiry && Date.now() < tabExpiry) {
      setActiveComponent(storedTab);
    } else {
      // Clear any expired data
      localStorage.removeItem('activeTab');
      localStorage.removeItem('tabExpiry');
    }

    return () => {
      // Add any cleanup logic if necessary
    };
  }, []);

  const handleTabChange = (newTab) => {
    setActiveComponent(newTab);

    // Store the active tab in localStorage with an expiry time
    localStorage.setItem('activeTab', newTab);
    localStorage.setItem('tabExpiry', Date.now() + 3600000); // 1 hour in milliseconds
  };

  const menuItems = [
    { text: 'Prompts', component: 'Prompts', icon: <InboxIcon /> },
    { text: 'Tasks', component: 'Tasks', icon: <TaskIcon /> },
    { text: 'Courses', component: 'Courses', icon: <SchoolIcon /> },
    { text: 'Schedules', component: 'Schedules', icon: <ScheduleIcon /> },
    { text: 'Users', component: 'Users', icon: <GroupIcon /> },
    { text: 'System Metrics', component: 'SystemMetrics', icon: <InsightsIcon /> },
    { text: 'App Metrics', component: 'AppMetrics', icon: <BarChartIcon /> },
    { text: 'Messages', component: 'Messages', icon: <MessageIcon /> },
  ];

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const renderComponent = () => {
    const componentProps = { handleTabChange }; // Pass the function as a prop
    switch (activeComponent) {
      case 'Prompts':
        return <Prompts {...componentProps} />;
      case 'Tasks':
        return <Tasks {...componentProps} />;
      case 'Courses':
        return <Courses {...componentProps} />;
      case 'Users':
        return <Users {...componentProps} />;
      case 'SystemMetrics':
        return <SystemMetrics {...componentProps} />;
      case 'AppMetrics':
        return <AppMetrics {...componentProps} />;
      case 'Schedules':
        return <Schedules {...componentProps} />;
      case 'Messages':
        return <Messages {...componentProps} />;
      default:
        return <Prompts {...componentProps} />;
    }
  };

  if (!authorized) {
    return (
      <div
        id="googleSignInDiv"
        style={{ display: 'flex', justifyContent: 'center', marginTop: '20%' }}
      ></div>
    );
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      {/* Collapsible Sidebar Drawer */}
      <Drawer
        variant="permanent"
        sx={{
          width: open ? drawerWidth : 60, // Adjust width when closed
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: open ? drawerWidth : 60,
            transition: 'width 0.4s ease', // Smooth animation for sidebar
            overflowX: 'hidden',
            bgcolor: '#1e3a8a', // Pleasant blue background
            position: 'fixed',
          },
        }}
      >
        <Toolbar />
        <Box
          sx={{ display: 'flex', justifyContent: open ? 'flex-end' : 'center', p: 1 }}
        >
          <IconButton
            onClick={handleDrawerToggle}
            sx={{
              bgcolor: 'rgba(255, 255, 255, 0.1)',
              color: 'white',
              borderRadius: 2, // Make the button square with slight rounding
              width: 40,
              height: 40,
              '&:hover': {
                bgcolor: 'rgba(255, 255, 255, 0.2)',
              },
              transition: 'transform 0.3s ease', // Smooth animation for icon rotation
              transform: open ? 'rotate(0deg)' : 'rotate(180deg)',
            }}
          >
            {open ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </Box>
        <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.3)' }} />

        <List>
          {menuItems.map((item) => (
            <ListItem
              button
              key={item.text}
              onClick={() => handleTabChange(item.component)} // Change active component on click
              sx={{
                padding: open ? '10px 16px' : '10px 8px',
                color: 'white', // White text
                fontWeight: 'bold', // Bold text
                '&:hover': {
                  bgcolor: 'rgba(255, 255, 255, 0.1)', // Lighter hover effect
                },
                backgroundColor:
                  activeComponent === item.component ? 'rgba(255, 255, 255, 0.2)' : 'inherit',
              }}
            >
              <ListItemIcon
                sx={{
                  color: 'white',
                  minWidth: 'unset', // Remove default padding from ListItemIcon
                  justifyContent: 'center', // Center the icon
                  display: 'flex', // Ensure flexbox layout
                  width: open ? 'auto' : '36px', // Adjust width when menu is collapsed
                }}
              >
                {item.icon}
              </ListItemIcon>
              <Collapse in={open} orientation="horizontal">
                <ListItemText
                  primary={item.text}
                  sx={{
                    fontWeight: 'bold',
                    paddingLeft: open ? '12px' : '0px', // Add padding between icon and text when menu is open
                  }}
                />
              </Collapse>
            </ListItem>
          ))}
        </List>
      </Drawer>

      {/* Main Content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: 'background.default',
          p: 3,
          transition: 'margin-left 0.4s ease',
          marginLeft: open ? `${drawerWidth}px` : '60px',
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ display: 'flex', justifyContent: 'center' }}>
          {renderComponent()} {/* Render the active component */}
        </Container>
      </Box>
    </Box>
  );
}

export default AdminDashboard;
