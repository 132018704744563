import React, { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { API_BASE_URL, GOOGLE_CLIENT_ID } from '../constants';

const FlightSeatSelector = () => {
  const [dates, setDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [seats, setSeats] = useState({});
  const [loading, setLoading] = useState(false);
  const [scheduleId, setScheduleId] = useState(null);
  const [userEmail, setUserEmail] = useState('');
  const [scheduleDetails, setScheduleDetails] = useState(null);

  const handleCredentialResponse = (response) => {
    const decoded = jwtDecode(response.credential);
    setUserEmail(decoded.email);
    localStorage.setItem('user', JSON.stringify(decoded));
    localStorage.setItem('expiry', Date.now() + 3600000); // 1 hour expiry
  };

  useEffect(() => {
    const loadGoogleSignIn = () => {
      if (typeof window.google !== 'undefined' && window.google.accounts) {
        window.google.accounts.id.initialize({
          client_id: GOOGLE_CLIENT_ID,
          callback: handleCredentialResponse,
        });
        window.google.accounts.id.renderButton(
          document.getElementById('googleSignInDiv'),
          { theme: 'outline', size: 'large' }
        );
      } else {
        setTimeout(loadGoogleSignIn, 100);
      }
    };

    const storedUser = localStorage.getItem('user');
    const expiry = localStorage.getItem('expiry');
    if (storedUser && expiry && Date.now() < expiry) {
      const decodedUser = JSON.parse(storedUser);
      setUserEmail(decodedUser.email);
    } else {
      localStorage.removeItem('user');
      localStorage.removeItem('expiry');
      loadGoogleSignIn();
    }
  }, []);

  const handleSignOut = () => {
    setUserEmail('');
    localStorage.removeItem('user');
    localStorage.removeItem('expiry');
    window.google.accounts.id.disableAutoSelect();
  };

  useEffect(() => {
    setLoading(true);
    fetch(`${API_BASE_URL}/internal/sget_plane_sched`)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setDates(data.schedules.map((schedule) => schedule.date));
        } else {
          console.error('Failed to fetch dates:', data.error);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching dates:', error);
        setLoading(false);
      });
  }, []);

  const handleDateChange = (event) => {
    const date = event.target.value;
    setSelectedDate(date);
    setLoading(true);

    fetch(`${API_BASE_URL}/internal/sget_plane_sched`)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          const schedule = data.schedules.find((sched) => sched.date === date);
          setSeats(schedule ? schedule.seat_map : {});
          setScheduleId(schedule ? schedule.id : null);

          // Set additional details for display
          if (schedule) {
            setScheduleDetails({
              destination: schedule.destination,
              tailNumber: schedule.tail_number,
              duration: schedule.time_duration,
            });
          } else {
            setScheduleDetails(null);
          }
        } else {
          console.error('Failed to fetch seat availability:', data.error);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching seat availability:', error);
        setLoading(false);
      });
  };

  const handleSeatClick = (seatId) => {
    const occupant = seats[seatId];
    if (!userEmail) {
      alert('Please sign in to reserve a seat.');
      return;
    }

    if (!occupant) {
      updateSeatAssignment(seatId, userEmail);
    } else if (occupant === userEmail || userEmail === 'diego@lokos.ai') {
      if (window.confirm('Do you want to free up this seat?')) {
        updateSeatAssignment(seatId, null);
      }
    } else {
      alert(`Seat ${seatId} is occupied.`);
    }
  };

  const updateSeatAssignment = (seatId, email) => {
    if (!scheduleId) {
      console.error('Schedule ID is not available.');
      return;
    }

    const updatedSeats = { ...seats, [seatId]: email };
    const payload = { id: scheduleId, changes: { seat_map: updatedSeats } };

    setLoading(true);
    fetch(`${API_BASE_URL}/internal/supdate_plane_sched`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setSeats(updatedSeats);
        } else {
          console.error('Failed to update seat assignment:', data.error);
          alert('Failed to update seat assignment.');
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error updating seat assignment:', error);
        setLoading(false);
      });
  };

  const renderSeatMap = () => {
    if (!seats || Object.keys(seats).length === 0) {
      return <p>No seat information available.</p>;
    }

    return (
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <h3>Seat Map for {selectedDate}</h3>
        {scheduleDetails && (
          <div style={{ marginBottom: '20px' }}>
            <p>Destination: {scheduleDetails.destination}</p>
            <p>Tail Number: {scheduleDetails.tailNumber}</p>
            <p>
              Duration: {Math.floor(scheduleDetails.duration / 3600)} hrs{' '}
              {Math.floor((scheduleDetails.duration % 3600) / 60)} mins
            </p>
          </div>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundImage: 'url("/piper_top_view.png")',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            height: '300px',
            width: '300px',
            position: 'relative',
            padding: '20px',
          }}
        >
          {['1', '2'].map((rowNumber) => (
            <div key={rowNumber} style={{ display: 'flex', justifyContent: 'center' }}>
              {['A', 'B'].map((seatLetter) => {
                const seatId = `${rowNumber}${seatLetter}`;
                const occupant = seats[seatId];
                const isPilotSeat = seatId === '1A';

                return (
                  <div
                    key={seatId}
                    onClick={() => !isPilotSeat && handleSeatClick(seatId)}
                    style={{
                      width: '50px',
                      height: '50px',
                      margin: '5px',
                      lineHeight: '50px',
                      textAlign: 'center',
                      borderRadius: '5px',
                      backgroundColor: occupant ? '#d3d3d3' : '#90ee90',
                      cursor: isPilotSeat ? 'not-allowed' : 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    title={
                      isPilotSeat
                        ? 'Pilot Seat - Assigned to Diego Casabuena'
                        : occupant
                        ? `Occupied by ${occupant}`
                        : 'Available'
                    }
                  >
                    {seatId}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        <p style={{ marginTop: '20px' }}>Pilot Seat: Assigned to Diego Casabuena</p>
      </div>
    );
  };

  return (
    <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
      <h2>Select a Flight Date</h2>
      {loading && <p>Loading...</p>}
      <div>
        {!userEmail ? (
          <div id="googleSignInDiv" />
        ) : (
          <div>
            <p>Signed in as {userEmail}</p>
            <button onClick={handleSignOut}>Sign Out</button>
          </div>
        )}
      </div>
      <select value={selectedDate} onChange={handleDateChange}>
        <option value="" disabled>
          Choose a date
        </option>
        {dates.map((date) => (
          <option key={date} value={date}>
            {date}
          </option>
        ))}
      </select>
      {selectedDate && renderSeatMap()}
    </div>
  );
};

export default FlightSeatSelector;
