import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Contact from './components/Contact';
import About from './components/About';
import AdminDashboard from './components/AdminDashboard';
import WebAudioDemo from './components/WebAudioDemo';
import FetchPage from './components/FetchPage';
import AnamAIComponent from './components/AnamAIComponent'
import FlightSeatSelector from './components/PlaneSeatMap'

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/experimental_admin" element={<AdminDashboard />} />
        <Route path="/experimental_fetch_page" element={<FetchPage />} />
        <Route path="/experimental_anam_demo" element={<AnamAIComponent />} />
        <Route path="/personal_travel" element={<FlightSeatSelector />} />
        <Route path="/:username" element={<WebAudioDemo />} />
      </Routes>
    </Router>
  );
}

export default App;
